import React from 'react';

function Loader( { spinnerColour = 'black' } ) {

    return (
    <div className="loader-container">
        <div className={spinnerColour === 'black' ? 'loader-black' : 'loader-white'}></div>
    </div>
    );
}

export default Loader