import React, { useEffect, useState } from 'react';
import api from '../api';
import Loader from '../components/shared/Loader';
import { useNavigate } from 'react-router-dom';

function AppPrivacy() {
  const [documentData, setDocumentData] = useState({ content: '', lastModified: '' });
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrivacy = async () => {
      setLoading(true);
      try {

        const result = await api.get('/docs/privacy');
        setDocumentData(result.data.document);

      } catch (err) {
        if (err) {
          navigate('*');
        }
      } finally {
        setLoading(false);
      }
    }

    fetchPrivacy();
  }, [navigate]);

  return (
    <div className='privacy-page-container docs-page'>
      <div className='info-container'>
        { loading ? (
           <Loader spinnerColour='black'/>
        ) : (
          <div className='document-container'>
            <small className='document-date'>Last Modified: { new Date(documentData.lastModified).toLocaleString('en-GB', { timeZone: 'UTC' }) }</small>
            <div className='page-text doc-content' dangerouslySetInnerHTML={{ __html: documentData.content }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AppPrivacy;
